<div class="modal-header"></div>

<div class="modal-body">
  <h1>{{ 'deleteFolderModal.title' | translate }}</h1>

  <div
    class="t-align-center"
    innerHtml="{{ 'deleteFolderModal.message' | translate: { folderName: folder.name } }}"
  ></div>

  <div class="buttons-group">
    <button
      [disabled]="isLoading"
      class="btn btn-primary decline-btn"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      [appBtnLoading]="isLoading"
      (click)="deleteFolder()"
      class="btn btn-primary submit-btn"
    >
      {{ 'common.buttons.delete' | translate }}
    </button>
  </div>
</div>
