<div class="modal-header"></div>

<div class="modal-body">
  <h1>{{ 'renameFolderModal.title' | translate }}</h1>

  <form
    autocomplete="off"
    [formGroup]="form"
  >
    <div
      class="form-group"
      [ngClass]="{ 'has-error': form.get('name').invalid }"
    >
      <input
        ngbAutofocus
        autocomplete="false"
        type="text"
        formControlName="name"
        placeholder="{{ 'renameFolderModal.placeholder' | translate }}"
      />
      <form-error-messages
        [control]="form.get('name')"
        [customErrors]="customErrors"
      >
      </form-error-messages>
    </div>
  </form>

  <div class="buttons-group">
    <button
      [disabled]="isLoading"
      class="btn btn-primary decline-btn"
      (click)="activeModal.dismiss()"
    >
      {{ 'common.buttons.cancel' | translate }}
    </button>
    <button
      type="submit"
      [appBtnLoading]="isLoading"
      (click)="renameFolder()"
      class="btn btn-primary submit-btn"
    >
      {{ 'common.buttons.save' | translate }}
    </button>
  </div>
</div>
